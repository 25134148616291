let sessionMonitorWorker = undefined;

function setupSessionMonitor(callback) {
  if (typeof (Worker) === "undefined") {
    return;
  }
  if (typeof (sessionMonitorWorker) === "undefined") {
    sessionMonitorWorker = new Worker("scripts/session-worker-lp.js");
    sessionMonitorWorker.onmessage = function (event) {
      if (!callback) {
        return;
      }
      callback(event.data);
    };
  }
}

function startSessionMonitor(token, proxyUrl) {
  sessionMonitorWorker.postMessage({
    token: token,
    proxyUrl: proxyUrl
  });
}

function stopSessionMonitor() {
  sessionMonitorWorker?.terminate();
  sessionMonitorWorker = undefined;
}

export {
  setupSessionMonitor,
  startSessionMonitor,
  stopSessionMonitor
}