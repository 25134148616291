import axios from "axios";
import { msalInstance, msalLoginInstance, Scopes, loginRequest } from "./msal";
import config from './configuration'

const axiosInstance = axios.create({
    baseURL: `https://${config.proxyUrl}/${config.proxyPrefix}`
});

axiosInstance.interceptors.request.use(
  async request => {
    if (request.headers) {
      request.headers['Content-Type'] = 'application/json';
    }

    const account = msalInstance.getAllAccounts()[0];
    if (account) {
      const accessTokenResponse = await msalInstance.acquireTokenSilent({
        scopes: Scopes,
        account: account
      });

      if (accessTokenResponse) {
        const accessToken = accessTokenResponse.accessToken

        if (request.headers && accessToken) {
          request.headers['Authorization'] = 'Bearer ' + accessToken;
        }
      }
    }
    return request;
  },
  error => {
    return Promise.reject(error);
  });

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    if (error?.response?.status === 401) {
      await msalLoginInstance.loginRedirect(loginRequest);
    }
    return error;
  });

export default axiosInstance;