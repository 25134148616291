import React from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../msal";
import Button from "react-bootstrap/Button";


/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const AuthenticationButton = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();

    const handleAuthentication = () => {
        if (!isAuthenticated) {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
        else {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }
    return (
        <Button variant="secondary" className="ml-auto" onClick={handleAuthentication}>
            {isAuthenticated ? "Logout" : "Login"}
        </Button>
    );
}