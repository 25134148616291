import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import {Card } from "react-bootstrap";

export const ApplicationTile = ({imageLink, name, redirect }) => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <Card body className="mb-4">
            <Card.Img variant="top" src={imageLink}/>
            <Card.Body>
                <Card.Title tag="h5">
                    {name}
                </Card.Title>
                <Card.Text>
                    With supporting text below as a natural lead-in to additional content.
                </Card.Text>
                <a className="btn btn-primary" href={redirect} target="_blank" rel="noreferrer">
                    {isAuthenticated ? "Go to" : "Login"}
                </a>
            </Card.Body>
            
        </Card>
    );
}
