import deepmerge from 'deepmerge'

var globalVarsProvider = (configuration) => {
    configuration = deepmerge(configuration, window.REACT_APP_VARIABLES);
    return configuration;
}

var envPrefix = "REACT_APP_"
var envConfigProvider = (configuration) => {
    for (var key in process.env) {
        if (key.startsWith(envPrefix)) {
            var value = process.env[key];
            key = key.substring(envPrefix.length);
            var parts = key.split("__");
            var config = configuration;
            for (var part in parts) {
                var name = parts[part];
                if (parseInt(part) === (parts.length - 1)) {
                    config[name] = value;
                } else {
                    if (config[name] === undefined) {
                        console.warn(`Missing config section: ${key}`)
                        break;
                    }
                    config = config[name]
                }
            }
        }
    }
    return configuration;
}

var providers = [
    globalVarsProvider,
    envConfigProvider
]

var configuration = {
    msal: {
        tenantName: "",
        clientId: "",
    }
}

for (var provider of providers) {
    configuration = provider(configuration);
}

export default configuration;