import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { loginRequest, msalRegisterInstance } from "../msal";
import Button from "react-bootstrap/Button";

/**
 * Renders a button which will redirect to the register page
 */
export const RegisterButton = () => {
    const isAuthenticated = useIsAuthenticated();
    const handleRegistration = () => {
        msalRegisterInstance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
    }
    return (<>
        {!isAuthenticated &&
            <Button variant="secondary" className="ml-auto" onClick={handleRegistration}>
                Register
            </Button>
        }
    </>);
}