import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { ApplicationTile } from './ApplicationTile';
import axiosInstance from '../axios';

const AppsList = function (apps: any) {
    return (
        <Row>
            {apps?.data?.map(app =>
                <Col xs={6} key={app.name}>
                    <ApplicationTile name={app.name} imageLink={app.logoUrl ?? ''} redirect={`${app.baseUrl}/${app.loginUrl ?? ''}`} />
                </Col>
            )}
        </Row>
    );
}

export default function Home() {
    const [status, setStatus] = useState({ apps: [], loading: true });

    const fetchData = async () => {
        const response = await axiosInstance.get('api/apps');
        setStatus({ apps: response.data, loading: false });
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (status.loading) {
        return (
            <p><em>Loading...</em></p>
        )
    }

    return (
        <div>
            <h1>Global Platform ROCKS!</h1>
            <AppsList data={status.apps} />
        </div>
    );
};
