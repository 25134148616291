import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { setupSessionMonitor, startSessionMonitor, stopSessionMonitor } from './workers/sessionWorker';
import config from './configuration'

const getMsalConfig = (policy) => {
  return {
    auth: {
      clientId: config.msal.clientId,
      authority: `https://${config.msal.tenantName}.b2clogin.com/${config.msal.tenantName}.onmicrosoft.com/${policy}`,
      knownAuthorities: [`${config.msal.tenantName}.b2clogin.com`], // You must identify your tenant's domain as a known authority.
      redirectUri: window.location.origin
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
}

const Scopes = ["openid", `https://${config.msal.tenantName}.onmicrosoft.com/app-portal-api/dummy`];

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
const loginRequest = {
  scopes: Scopes,
};

const logoutRequest = {
  postLogoutRedirectUri: "/",
};

const msalLoginInstance = new PublicClientApplication(getMsalConfig("B2C_1A_LOGIN"));
const msalRegisterInstance = new PublicClientApplication(getMsalConfig("B2C_1A_REGISTER"));
const msalInstance = msalRegisterInstance.getAllAccounts()[0]
  ? msalRegisterInstance
  : msalLoginInstance;

msalInstance.handleRedirectPromise()
    .then(function (response) {
        // success
    })
    .catch((error) => {
        if (error.errorMessage.startsWith("AADB2C90091")) {
            console.log("redirect to login");
        } else {
            console.log(error);
        }
    })

const toggleSessionMonitoring = (event) => {
  if (event?.eventType === EventType.INITIALIZE_END) {
    const account = msalInstance.getAllAccounts()[0];
    if (account) {
      msalInstance.acquireTokenSilent({
        scopes: Scopes,
        account: account
      });
    }
    setupSessionMonitor((sessionStatus) => {
      if (sessionStatus === true) {
        return;
      }
      msalInstance.logoutRedirect(logoutRequest);
    });
  } else if (event?.eventType === EventType.LOGIN_SUCCESS || event?.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
    startSessionMonitor(event.payload.accessToken, config.proxyUrl);
  }
  else if (event?.eventType === EventType.LOGOUT_SUCCESS) {
    stopSessionMonitor();
  } else if(event?.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    msalInstance.logoutRedirect(logoutRequest);
  }
}
msalLoginInstance.addEventCallback(toggleSessionMonitoring);
msalRegisterInstance.addEventCallback(toggleSessionMonitoring);

export {
  msalInstance,
  msalLoginInstance,
  msalRegisterInstance,
  Scopes,
  loginRequest
}